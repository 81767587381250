import { useCallback, useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { Chart, Table } from '../components';
import DateRangeSelect from '../components/daterange/DateRange';
import styles from '../styles/Home.module.scss';
import { FilmMakerNews } from '@components/FilmmakerNews/FilmMakerNews';
import { config } from '../config';
import { getFirstDayOfNextMonth, isCurrentMonth, getCurrentQuarter, getMonthName } from '../utils';
import { CSVLink } from 'react-csv';
import classNames from 'classnames';
import Link from 'next/link';
import format from 'date-fns/format';
import { useContributorMovies } from 'hooks/useContributorMovies';
import { useUser } from 'hooks/useUser';
import { useRouter } from 'next/router';
import { FilmsGrid } from '@components/FilmsGrid/FIlmsGrid';

export default function Home() {
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const [viewsData, setViewsData] = useState<any>({});
  const [totalViews, setTotalViews] = useState<any>([0]);
  const [totalWatch, setTotalWatch] = useState<any>(0);
  const [totalFree, setTotalFree] = useState<any>(0);
  const [totalFreeViews, setTotalFreeViews] = useState<any>(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableMonthsData, setTableMonthsData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [range, setRange] = useState<{ initDate: string; endDate: string; contributor: any }>();
  const [initDate, setInitDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [totalEarned, setTotalEarned] = useState<any>(0);
  const currentYear = new Date().getFullYear();
  const [monthInfo, setMonthInfo] = useState<string>('');

  const contributorMovies = useContributorMovies();
  const isAdmin = useMemo(() => user?.groups.some((group: string) => group === 'Admins'), [user?.groups]);

  const router = useRouter();
  const { slug } = router.query;

  const CHART_DATA = {
    labels: viewsData['chartLabels'],
    datasets: [
      {
        label: 'Views',
        data: viewsData['chartData'],
      },
    ],
  };

  const TABLE_COLUMNS = useMemo(() => [
    {
      Header: 'Title',
      accessor: 'title', // accessor is the "key" in the data
      Cell: ({ value, row }) => (
        <Link
            href={ {
              pathname: `/film-manager/${ row.original._slug }/views`,
              query: {
                ini: `${
                  row.original.views > 0 ? initDate : row.original.uploadDate
                }`,
                end: `${
                  row.original.views > 0
                    ? endDate
                    : format(Date.now(), 'yyyy-MM-dd')
                }`,
              },
            } }
          >
          <a className={ `hover:underline` }>
            <span>{ value }</span>
          </a>
        </Link>
      ),
    },
    {
      Header: 'Contributor email',
      accessor: 'contributorEmail',
    },
    {
      Header: 'Upload date',
      accessor: 'uploadDate',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value, row }) => (
        <label
          className={ classNames({
            'text-success': value === 'Published',
            'text-warning': value === 'Review',
          }) }
        >
          { value }
        </label>
      ),
    },
    {
      Header: 'Minutes',
      accessor: 'minutes',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Views',
      accessor: 'views',
      Cell: ({ value, row }) => {
        return (
          <Link
            href={ {
              pathname: `/film-manager/${ row.original._slug }/views`,
              query: {
                ini: `${
                  row.original.views > 0 ? initDate : row.original.uploadDate
                }`,
                end: `${
                  row.original.views > 0
                    ? endDate
                    : format(Date.now(), 'yyyy-MM-dd')
                }`,
              },
            } }
          >
            <a className={ 'hover:underline' }>
              <span>{ value }</span>
            </a>
          </Link>
        );
      },
    },
  ], [endDate, initDate]);

  useEffect(() => {
    if (range) {
      setInitDate(range.initDate);
      setEndDate(range.endDate);
    }
  }, [range]);

  const updateRange = (initDate: any, endDate: any, contributor: any): any => {
    setRange({ initDate, endDate, contributor });
  };

  const getViewsDataInfo = useCallback(
    async ({ initDate, endDate, contributor }: { initDate: string; endDate: string, contributor: any }) => {
      try {
        setLoading(true);
        const body = JSON.stringify({ iniDate: initDate, endDate: endDate, slug, contributor });
        const url = slug ? '/api/mux/views/detail' : '/api/mux/views/list';
        const getViewsData = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: body,
        });

        const viewsData = await getViewsData.json();

        let totalViewsData: any = {};
        let totalWatchTime: any = 0
        let totalFreeTime: any = 0

        if (viewsData.result === 'success') {
          const data = viewsData.data;
          const info = data.info;
          totalWatchTime = (data.total_watch_time.total / 3600000).toFixed(2);
          totalFreeTime = (data.total_watch_time.free / 3600000).toFixed(2);
          setTotalFreeViews(data.total_free_views);
          let dataInfo = [];

          if (isAdmin) {
            for (let key in info) {
              if (info.hasOwnProperty(key)) {
                const obj = info[key];
                const slug = key.split('|')[0];
                const minutes = (obj['watch_time'] / 60000).toFixed(2);

                const tableReg = {
                  _slug: slug,
                  title: obj['title'],
                  contributorEmail: obj['contributorEmail'],
                  uploadDate: obj['uploadDate'].split('T')[0],
                  status: obj['status'],
                  minutes: minutes,
                  amount: obj['amount'].toFixed(2),
                  views: obj['views'],
                };

                if (!user.groups.includes('Admins') && isCurrentMonth(initDate)) {
                  tableReg.amount = 'Available on ' + getFirstDayOfNextMonth().toISOString().split('T')[0];
                }

                dataInfo.push(tableReg);
              }
            }
            setTableData(dataInfo);
          } else if (data.quarters_info !== undefined) {
            const endDateParts = endDate.split('-');
            const year = parseInt(endDateParts[0]);
            const month = parseInt(endDateParts[1]);
            let totalAmount = 0;
            let monthsInfo = [];
            if (year === new Date().getFullYear() && month === new Date().getMonth() + 1) {
              setMonthInfo('Total previous month: $ 0.00');
            } else {
              setMonthInfo('Total ' + getMonthName(month - 1) + ': $ 0.00');
            }
            
            for (let key in data.quarters_info) {
              if (data.quarters_info.hasOwnProperty(key)) {
                const obj = data.quarters_info[key];
                const tableReg = {
                  quarter: obj['year'].toString() + ' - Q' + obj['quarter'].toString(),
                  earned: '$ ' + obj['amount'],
                };
                totalAmount += parseFloat(obj['amount']);
                dataInfo.push(tableReg);
              }
            }

            const currentQuarterInfo = {
              quarter: currentYear.toString() + ' - ' + getCurrentQuarter(new Date().getMonth() + 1, 3).value,
              earned: 'In Progress',
            }

            for (let key in data.months_info) {
              if (data.months_info.hasOwnProperty(key)) {
                const obj = data.months_info[key];
                const tableReg = {
                  year: obj['year'],
                  month: obj['monthName'],
                  earned: '$ ' + obj['amount'].toFixed(2),
                };
                monthsInfo.push(tableReg);

                if (year === obj['year'] && month === obj['month'] && 
                (month !== new Date().getMonth() + 1 || year !== new Date().getFullYear())) {
                  setMonthInfo('Total ' + obj['monthName'] + ': ' + tableReg.earned);
                } 
              }
            }

            if (monthInfo === '' || (year === new Date().getFullYear() && month === new Date().getMonth() + 1)) {
              const monthInfoObj = monthsInfo[monthsInfo.length - 1];
              if (monthInfoObj) {
                setMonthInfo('Total previous month: ' + monthInfoObj.earned);
              }
            }

            const currentMonthInfo = {
              year: currentYear,
              month: getMonthName(new Date().getMonth()),
              earned: 'In Progress',
            }

            dataInfo.push(currentQuarterInfo);
            monthsInfo.push(currentMonthInfo);
            setTotalEarned(totalAmount);            
            setTableData(dataInfo);
            setTableMonthsData(monthsInfo);
          }

          const dataViewsChart = data.views;

          let chartLabels = [];
          let chartData = [];
          let viewsCount = 0;

          for (let key in dataViewsChart) {
            if (dataViewsChart.hasOwnProperty(key)) {
              const obj = dataViewsChart[key];
              chartLabels.push(obj['day']);
              chartData.push(obj['views']);
              viewsCount += parseInt(obj['views']);
            }
          }

          totalViewsData['chartLabels'] = chartLabels;
          totalViewsData['chartData'] = chartData;
          setTotalViews(viewsCount);
        }

        setViewsData(totalViewsData);
        setTotalWatch(totalWatchTime);
        setTotalFree(totalFreeTime);

      } catch (error: any) {
        console.log('ERROR', error.message);
      } finally {
        setLoading(false);
      }
    },
    [user, isAdmin, slug],
  );

  useEffect(() => {
    if (user && typeof range !== 'undefined') {
      getViewsDataInfo(range);
    }
  }, [user, range]);

  const TABLE_QUARTER_COLUMNS = [
    {
      Header: 'Quarter',
      accessor: 'quarter',
    },
    {
      Header: 'Earning',
      accessor: 'earned',
    },
  ]
  const TABLE_MONTH_COLUMNS = [
    {
      Header: 'Year',
      accessor: 'year',
    },
    {
      Header: 'Month',
      accessor: 'month',
    },
    {
      Header: 'Earning',
      accessor: 'earned',
    },
  ]

  return (
    <>
      <Head>
        <title>Popflick - Admin</title>
        <meta name="description" content="Generated by create next app"/>
        <link
          rel="icon"
          href={ `${ config.staticFileBaseUrl }/images/favicon.ico` }
        />
      </Head>

      <main className={ styles.main }>
        <div className="flex flex-row justify-center">
          <div className="flex flex-1 flex-column">
            { !isAdmin && (
              <>
                <h1 className="text-2xl text-bold">
                  Welcome { user?.first_name }! Thank you for working with us.
                </h1>
                {!user?.hide_statistics &&
                  <h2 className="text-xl text-bold text-popflick-yellow mt-12">
                    Your Film Statistics
                  </h2>
                }
              </>
            ) }
            {!user?.hide_statistics &&
              <>
                <div className="mt-5">
                  <div>
                    <DateRangeSelect dataUpdaterHandler={ updateRange }/>
                  </div>
                </div>
                <div className={ `my-4` }>
                  <Chart 
                    title={ isAdmin ? (
                        `Total views: ${ totalViews } | Free Views: ${ totalFreeViews } | Total Hours: ${ totalWatch } | Free Hours: ${ totalFree }` 
                      ) : (
                        `Total views: ${ totalViews } | Total Hours: ${ totalWatch }`
                      )
                    } 
                    data={ CHART_DATA } loading={loading} 
                  />
                </div>
              </>
            }
            { isAdmin ? (
              <>
                <div className={ `flex justify-end my-4` }>
                  <CSVLink className={ `btn btn-yellow` } data={ tableData }>
                    Download
                  </CSVLink>
                </div>
                <Table
                  title="Movies"
                  columns={ TABLE_COLUMNS }
                  data={
                    filteredData && filteredData.length /*|| searchInputVal*/
                      ? filteredData
                      : tableData
                  }
                  range={ range }
                />
              </>
            ) : (
              <>
                {!user?.hide_statistics &&
                  <>
                    <h2 className="text-xl text-bold text-popflick-yellow mt-12">
                    <p>{monthInfo}</p>
                      Total earnings since uploaded: $ {totalEarned.toFixed(2)}
                    </h2>
                    <div className={ `flex justify-end my-4` }></div>
                    {/* <Table
                      title="Earnings"
                      columns={ TABLE_QUARTER_COLUMNS }
                      data={ tableData }
                      range={ range }
                    /> */}
                    <Table
                      title="MonthEarnings"
                      columns={ TABLE_MONTH_COLUMNS }
                      data={ tableMonthsData }
                      range={ range }
                    />
                  </>
                }
                <FilmsGrid films={ contributorMovies }/>
              </>
            ) }
          </div>
          { !isAdmin ? (
            <div className="hidden md:block">
              <FilmMakerNews/>
            </div>
          ) : (
            <></>
          ) }
        </div>
      </main>
    </>
  );
}
