import type { News } from "./FilmmakerNews.types";
import { useQuery } from "@apollo/client";
import { FILMMAKER_NEWS } from "queries/contributor";

type QueryResponse = {
  Articles: {
    items: News[];
  }
}

export const FilmMakerNews = () => {
  const news = useQuery<QueryResponse>(FILMMAKER_NEWS);

  return (
    <div className="mt-24 ml-24 flex flex-column w-[250px]">
      <h3 className="text-lg w-[250px] mb-8 font-bold">
        <u className="decoration-popflick-yellow decoration-4 underline-offset-8">
          Filmmaker News
        </u>
      </h3>
      {news.loading || news.error ? (
        <></>
      ) : (
        news.data.Articles.items.map((newsItem) => (
          <a
            key={newsItem.slug}
            className="mb-8 text-sm"
            href={`https://www.popflick.com/blog/${newsItem.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            {newsItem.title}
          </a>
        ))
      )}
    </div>
  );
};
