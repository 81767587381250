import { gql } from "@apollo/client";

export const CONTRIBUTOR_MOVIES = gql`
  query Query($contributorId: String) {
    Videos(where: { contributor_id: $contributorId }) {
      items {
        slug: _slug
        changedOn: _changed_on
        createdOn: _created_on
        publishOn: _publish_on
        posterHorizontal: poster_h
        title
      }
    }
  }
`;

export const FILMMAKER_NEWS = gql`
  query Query {
    Articles(limit: 6) {
      items {
        slug: _slug
        title
        author
      }
    }
  }
`;
