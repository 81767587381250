import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { format } from "date-fns";
import { MovieItemProps } from "./MovieItem.types";
import { useOutsideCallback } from "hooks/useOutsideCallback";
import { config } from "@config/index";

const MovieItem: FC<MovieItemProps> = ({ movie }) => {
  const tooltipRef = useRef(null);
  const openTooltipButtonRef = useRef(null);
  useOutsideCallback(tooltipRef, () => setOpenTooltip(false), [
    openTooltipButtonRef,
  ]);

  const [openTooltip, setOpenTooltip] = useState(false);

  const statusDate = useMemo(() => {
    if (movie.status === "Done") {
      const formattedDate = format(new Date(movie.publishOn), "dd/MM/yyyy");
      return `Live since ${formattedDate}`;
    }
    if (movie.status === "Review" || movie.status === "In progress") {
      const formattedDate = format(new Date(movie.changedOn), "dd/MM/yyyy");
      return `Updated on ${formattedDate}`;
    }
  }, [movie]);

  const status = useMemo(() => {
    if (movie.status === "Review") {
      return <label className="text-popflick-yellow">Pending Approval</label>;
    }
    if (movie.status === "In progress") {
      return <label className="text-popflick-yellow">In Progress</label>;
    }
    return null;
  }, [movie]);

  const toggleOpenTooltip = useCallback(() => {
    setOpenTooltip((prev) => !prev);
  }, []);

  return (
    <div className="mt-16 w-full md:w-[45%] mr-0 md:mr-[10%]  md:even:mr-0 relative">
      <div className="relative w-full aspect-video">
        <Image 
          layout="fill" 
          src={movie.posterHorizontal.length ? movie.posterHorizontal : `${config.staticFileBaseUrl}/images/image_placeholder.jpg`} 
          alt={movie.title} 
          sizes="(min-width: 1020px) 25vw, (min-width: 820px) 35vw, (min-width: 720px) 40vw, 55vw"
        />
      </div>
      <div className="mt-2 flex flex-row justify-between items-center">
        <div>
          <h3 className="text-base text-popflick-yellow">
            {movie.title}
          </h3>
          <p className="mt-1 text-xs">
            {statusDate} {status ? "-" : null} {status}
          </p>
        </div>
        {movie.status === "Done" && (
          <span
            ref={openTooltipButtonRef}
            className="font-bold text-4xl py-1 pl-1 cursor-pointer select-none"
            onClick={toggleOpenTooltip}
          >
            ···
          </span>
        )}
      </div>
      <div
        ref={tooltipRef}
        className={`${
          openTooltip ? "block" : "hidden"
        } movie-dropdown absolute -right-4 -bottom-16 bg-black shadow-tooltip w-[50%] border-2 border-white rounded pl-8 py-3 z-10`}
      >
        <ul className="flex flex-col">
          <li className="text-white text-sm cursor-pointer hover:text-popflick-yellow">
            <Link
              href={{
                pathname: "/",
                query: {
                  slug: movie.slug,
                  title: movie.title,
                },
              }}
              replace
            >
              <a>View Stats</a>
            </Link>
          </li>
          {/* <li className="text-white text-sm cursor-pointer hover:text-popflick-yellow">
            <Link href={`/film-manager/${movie.slug}`}>
              <a>Edit upload</a>
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default memo(MovieItem);
